import * as React from "react";
import { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Link,
  Typography,
  Stack,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CustomButton, CustomLoader } from "../Common";
import SignaturePad from "react-signature-canvas";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {
  AddTeamMembers,
  GetAllCreditAvailability,
  getCredits,
} from "../../Services/APIs";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ReactComponent as TeamSearchIcon } from "../../assets/images/teamSearch.svg";
import { SuccessMessage } from "./SuccessMessage";
import { getLocalData } from "../../Utils";

export const ProfileModals = ({
  open,
  message,
  handleClose,
  fullWidth,
  type,
  setFormData,
  formData,
  showCredits,
  handleActionBtn,
  handleNext,
  getclientOptions,
  clientDetail,
  handlePaymentWithExistingCard,
  getFamily,
  liabilityWaiverContent,
}) => {
  let sigCanvas = useRef(null);
  const clientId = getLocalData("clientId");
  const locationId = getLocalData("locationId");
  const [isSign, setIsSign] = useState(false);
  const [signError, setSignError] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setCheckError] = useState("");
  const [openConfirmation, setConfirmation] = useState(false);
  const [IsFamilyError, setIsFamilyError] = useState(false);
  const [signImageFile, setSignImageFile] = useState(null);
  const [familyError, setFamilyError] = useState("");
  const [clientList, setClientOptions] = useState([]); // Assuming getclientOptions is your initial data
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [availableCredits, setAvailableCredits] = useState([]);

  React.useEffect(() => {
    if (formData?.signatureImage) {
      setIsSign(true);
    }
  }, [formData]);

  React.useEffect(() => {
    if (type === "availableCredits" && locationId && clientId && showCredits) {
      handleGetAvailableCredits();
    }
  }, [showCredits]);

  const handleGetAvailableCredits = async () => {
    const clientId = getLocalData("clientId");
    const locationId = getLocalData("locationId");
    const response = await GetAllCreditAvailability(locationId, clientId);
    if (response?.statusCode === 200) {
      let activeCredits = response?.data?.Items?.filter(
        (creditItem) => creditItem?.remainingSession > 0 
        && creditItem?.refundStatus !== 'COMPLETED'
        && creditItem?.subscriptionStatus !== 'EXPIRED'
      );
      setAvailableCredits(activeCredits);
    }
  };

  // convert base64 data to file object
  const getFileFromUrl = async (fileurl) => {
    let fileSign;
    let filename = (Math.random() + 1).toString(36).substring(7);
    await fetch(fileurl)
      .then((res) => res.blob())
      .then((blob) => {
        fileSign = new File([blob], `${filename}_${Date.now()}.png`, {
          type: "image/png",
        });
      });
    return fileSign;
  };
  const saveSignature = async () => {
    let fileSign;
    // convert base64 to file object if signature Image await
    fileSign = await getFileFromUrl(
      sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    );
    const file = new File([fileSign], "ys766_1701173350970.png", {
      type: "image/png",
    });
    const fileURL = URL.createObjectURL(file);
    setFormData({
      ...formData,
      signature: fileSign,
      isLiabilityWaiverSigned: true,
      signatureImage: fileURL,
      signImg: fileURL,
    });
    setSignImageFile(fileSign);
  };

  const handleCloseModal = async () => {
    if (
      isSign &&
      !formData?.signatureImage &&
      formData.policyCheck &&
      !sigCanvas.isEmpty()
    ) {
      setIsSign(true);
      setSignError(false);
      saveSignature();
      const image = signImageFile;

      setFormData({
        ...formData,
        signature: image,
        isLiabilityWaiverSigned: true,
      });
      handleClose();
    } else if (
      !formData.policyCheck ||
      !formData?.isLiabilityWaiverSigned ||
      !isSign
    ) {
      setSignError(true);
    } else if (formData.policyCheck) {
      setSignError(false);
      handleClose();
    }
    //  else {
    //   setSignError(true);
    // }
  };

  const clearSignature = () => {
    sigCanvas?.current !== null && sigCanvas.clear();
    setFormData({ ...formData, signatureImage: null, policyCheck: false });
    setIsSign(false);
    setSignError(false);
  };
  const handleSign = () => {
    setSignError(false);
  };

  const handleCloseBuildFamily = () => {
    setCheckError("");
    setSearchValue("");
    setIsChecked(false);
  };

  const handleCategoryChange = (e, key) => {
    setCheckError("");
    const { value } = e.target;
    setSearchValue(value);
    setClientOptions(key);
  };

  //accepts terms on add team poppu
  const handleCheckBoxChange = (event) => {
    setCheckError("");
    const { name, checked } = event.target;
    if (name === "agreeBox") {
      setIsChecked(checked);
    }
  };

  const openConfirmationModal = (name) => {
    const FamilyMemberExists = getFamily?.Items?.some(
      (option) => option?.sortKey === clientList?.sortKey
    );
    if (FamilyMemberExists) {
      setCheckError("Family member already exist.");
      return;
    }
    if (searchValue === "") {
      setCheckError("Search box cannot be empty.");
      return;
    }
    if (!clientList?.firstName) {
      setCheckError(
        "Search box cannot be empty. Please select a valid member from the list."
      );
      return;
    }
    if (!isChecked) {
      setCheckError("Please accept terms & conditions.");
      return;
    }
    if (clientDetail.sortKey === clientList.sortKey) {
      setCheckError("You can not add yourself");
      return;
    }
    setIsFamilyError(false);
    setFamilyError("");
    setConfirmation(true);
  };

  const handleError = () => {
    setConfirmation(false);
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
    if (handleNext) {
      handleNext();
    }
  };
  const AddTeam = () => {
    const result = {
      franchiseeId: clientList.franchiseeId,
      locationId: clientDetail.locationId,
      addresseeId: clientList.sortKey,
      requesterId: clientDetail.sortKey,
      createdBy: clientDetail.firstName,
      status: 1,
    };
    setIsLoading(true);
    AddTeamMembers(result).then((response) => {
      setIsLoading(false);
      setIsFamilyError(false);
      setFamilyError("");
      if (response?.statusCode === 200) {
        setShowSuccessMessage(true);
        if (response?.message === "") {
          setSuccessMessage(response?.data);
        } else {
          setSuccessMessage("Team member has been added successfully!");
        }
        if (handleClose) {
          handleClose(); // Call handleNext if it's defined
        }
        setConfirmation(false);
      } else {
        setConfirmation(false);
        setIsFamilyError(true);
        setFamilyError(response.data.message);
      }
    });
  };
  console.log(availableCredits, "availableCreditsavailableCredits");
  return (
    <>
      {" "}
      <CustomLoader IsLoading={isLoading} />
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={fullWidth ? "md" : ""}
        open={open}
        onClose={() => {
          setSignError(false);
          handleClose();
        }}
        className={`global-modal-main ${
          type === "team" || type === "invite" || type === "availableCredits"
            ? openConfirmation
              ? "confirm-team-box"
              : "teams-main-wrap"
            : type === "confirm" || type === "dog"
            ? "confirm-main-wrap"
            : ""
        }`}
      >
        {type === "CartDelete" ||
        type === "promoCode" ||
        type === "giftCard" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">
                {type === "CartDelete"
                  ? "Item Removed"
                  : type === "giftCard"
                  ? "Gift Card"
                  : "Promo Code"}
              </Typography>
              <Typography className="modal-description">
                {type === "CartDelete"
                  ? "Item removed successfully."
                  : `${
                      type === "giftCard" ? "Gift card" : "Promo code"
                    } applied successfully.`}
              </Typography>
            </Box>
            <DialogActions>
              <Button
                className="red-btn"
                onClick={() => handleActionBtn("notNow")}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "attendeeDogProfile" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">Error</Typography>
              <Typography className="modal-description">
                Pet details not found.
              </Typography>
            </Box>
            <DialogActions>
              <Button className="red-btn" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "InvalidUrlMsg" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">Error</Typography>
              <Typography className="modal-description">
                You have triggered invalid url address, Please check url address
                and try again.
              </Typography>
            </Box>
            <DialogActions>
              <Button className="red-btn" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : // : type === "settingPwdReset" ? (
        //   <>
        //     <Box className="confirm-main">
        //       <Typography className="modal-heading ">
        //         Password Change
        //       </Typography>
        //       <Typography className="modal-description">
        //         Your current password is incorrect please try again.
        //       </Typography>
        //     </Box>
        //     <DialogActions>
        //       <Button className="red-btn" onClick={handleClose}>
        //         Close
        //       </Button>
        //     </DialogActions>
        //   </>
        // )
        type === "HelpFeedBack" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">Success</Typography>
              <Typography className="modal-description">
                Your feedback has been sent successfully.
              </Typography>
            </Box>
            <DialogActions>
              <Button className="red-btn" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "settingPwdChanged" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">
                Password Changed
              </Typography>
              <Typography className="modal-description">
                Password Changed Successfully.
              </Typography>
            </Box>
            <DialogActions>
              <Button className="red-btn" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "Cancel Schedule" || type === "Schedule Deleted" ? (
          <Box>
            <Box
              className="confirm-team-box"
              sx={{ padding: "30px !important", maxWidth: "379px !important" }}
            >
              <Typography className="modal-heading" fontWeight={"bold"} mb={1}>
                {type === "Schedule Deleted" ? "Success!" : "Cancel Schedule?"}
              </Typography>
              <Typography
                className="modal-description-confirm"
                sx={{
                  fontWeight: 700,
                  fontFamily: "UniversLTStd",
                  lineHeight: "21px !important",
                }}
              >
                {type === "Schedule Deleted"
                  ? "Your service has been cancelled."
                  : message}
                {/* // : "You are cancelling your scheduled service. As this is an early cancel, your credits will be refunded to your account. Are you sure you want to cancel the service?"} */}
              </Typography>
            </Box>
            <DialogActions style={{ marginBottom: "2px" }}>
              {type !== "Schedule Deleted" ? (
                <>
                  <Button
                    className="red-btn"
                    style={{
                      color: "#d13a00",
                      textTransform: "capitalize",
                      fontWeight: "700",
                    }}
                    onClick={handleNext}
                  >
                    {" "}
                    Yes{" "}
                  </Button>
                  <Divider className="profile-btn-divider" />
                  <Button
                    className="blue-btn"
                    style={{
                      color: "#003087",
                      textTransform: "capitalize",
                      fontWeight: "700",
                    }}
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="blue-btn"
                    style={{
                      color: "#003087",
                      textTransform: "capitalize",
                      fontWeight: "700",
                    }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </>
              )}
            </DialogActions>
          </Box>
        ) : type === "successCart" ||
          type === "error" ||
          type === "removeCart" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">
                {type === "successCart"
                  ? "Success"
                  : type === "removeCart"
                  ? "Removed"
                  : "Failed"}
              </Typography>
              <Typography className="modal-description">
                {type === "successCart"
                  ? "Item already exists in the cart."
                  : type === "removeCart"
                  ? "Selected item removed successfully from cart."
                  : "Something went wrong."}
              </Typography>
            </Box>
            <DialogActions>
              <Button className="red-btn" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "Failed" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">Failed</Typography>
              <Typography className="modal-description">
                Something went wrong, Please try again.
              </Typography>
            </Box>
            <DialogActions>
              <Button
                className="red-btn"
                onClick={() => handleActionBtn("notNow")}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "deleteClientWarning" ? (
          <>
            <Box className="confirm-delete-popup">
              <Typography className="modal-heading ">Alert</Typography>
              <Typography
                className="modal-description"
                sx={{ maxWidth: 375, textAlign: "center" }}
              >
                Deleting your account will deactivate any credits you may have
                on account with us, and you will no longer be able to access our
                system. For additional information on this deletion process
                please contact - <br />
                <a href="mailto:techsupport@zoomroom.com">
                  techsupport@zoomroom.com
                </a>
                {/* Deleting your account will <br />
                  - Delete your account info and profile photo <br />
                  - Delete all the dogs associated with your profile <br />
                  - Delete all your purchased credits <br />
                  - Delete all your purchase and service history <br />- Delete
                  all the trophies earned by your dog */}
              </Typography>
            </Box>
            <DialogActions>
              <Button className="red-btn" onClick={handleClose}>
                No
              </Button>
              <Button
                className="red-btn"
                sx={{ color: "#e35205", borderLeft: "1px solid lightgray" }}
                onClick={handleActionBtn}
              >
                Yes
              </Button>
            </DialogActions>
          </>
        ) : type === "paymentFailed" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">Payment Failed</Typography>
              <Typography className="modal-description">
                Payment failed please try again after some time.
              </Typography>
            </Box>
            <DialogActions>
              <Button
                className="red-btn"
                onClick={() => handleActionBtn("notNow")}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "paymentPanding" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">
                Payment Pending
              </Typography>
              <Typography className="modal-description">
                Payment goes to pending state please check your transation.
              </Typography>
            </Box>
            <DialogActions>
              <Button
                className="red-btn"
                onClick={() => handleActionBtn("notNow")}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : type === "selectPaymentCard" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">
                Confirm Payment
              </Typography>
              <Typography className="modal-description">
                Are you sure you want to use this card for payment.
              </Typography>
            </Box>
            <DialogActions>
              <Button
                className="red-btn"
                onClick={handlePaymentWithExistingCard}
              >
                Confirm
              </Button>
              <Button
                className="red-btn"
                onClick={() => handleActionBtn("notNow")}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : // type === "confirm" ||
        type === "dog" ? (
          <>
            <Box className="confirm-main">
              <Typography className="modal-heading ">
                Profile Completed
              </Typography>
              <Typography className="modal-description">
                {type === "dog"
                  ? "You have successfully completed your dog profile. Do you want to add another dog?"
                  : "You have successfully completed your profile. Do you want to build your team now? Before adding someone to your team, they should already have an account created."}
              </Typography>
            </Box>
            <DialogActions>
              <Button
                className="red-btn"
                onClick={() => handleActionBtn("notNow")}
              >
                Not Now
              </Button>
              <Divider className="profile-btn-divider" />
              <Button
                className="blue-btn"
                onClick={() => handleActionBtn("yes")}
              >
                Yes
              </Button>
            </DialogActions>
          </>
        ) : type === "team" || type === "invite" ? (
          <>
            {openConfirmation ? (
              ""
            ) : (
              <Box className="modal-header">
                <Button
                  onClick={() => {
                    handleClose();
                    handleCloseBuildFamily();
                  }}
                  className="close-button"
                >
                  <CloseIcon />
                </Button>
              </Box>
            )}
            <Box className={`team-main ${type === "invite" && "invite-main"}`}>
              {type === "invite" && <GroupsOutlinedIcon />}

              <Typography className="team-main-heading">
                {type === "invite"
                  ? "Invite Team Members"
                  : openConfirmation
                  ? ""
                  : "Build Your Family"}
              </Typography>
              <Box className="teams-content">
                {type === "invite" ? (
                  <Typography className="invite-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec ultricies accumsan aliquet.
                  </Typography>
                ) : (
                  <>
                    {openConfirmation ? (
                      <>
                        {IsFamilyError ? (
                          <Box>
                            <Box className="confirm-team-box">
                              <Typography className="modal-description-confirm">
                                {familyError}
                              </Typography>
                            </Box>
                            <DialogActions style={{ marginBottom: "2px" }}>
                              <Button
                                className="red-btn"
                                style={{
                                  color: "#d13a00",
                                  textTransform: "capitalize",
                                  fontWeight: "700",
                                }}
                                onClick={handleError}
                              >
                                {" "}
                                Cancel{" "}
                              </Button>
                            </DialogActions>
                          </Box>
                        ) : (
                          <Box>
                            <Box className="confirm-team-box">
                              <Typography
                                className="modal-description-confirm"
                                px={2}
                              >
                                Are you sure you want to add{" "}
                                {clientList?.firstName} to your family?
                              </Typography>
                            </Box>
                            <DialogActions style={{ marginBottom: "2px" }}>
                              <Button
                                className="red-btn"
                                style={{
                                  color: "#d13a00",
                                  textTransform: "capitalize",
                                  fontWeight: "700",
                                }}
                                onClick={() => {
                                  setConfirmation(false);
                                  handleCloseBuildFamily();
                                }}
                              >
                                {" "}
                                No{" "}
                              </Button>
                              <Divider className="profile-btn-divider" />
                              <Button
                                className="blue-btn"
                                style={{
                                  color: "#003087",
                                  textTransform: "capitalize",
                                  fontWeight: "700",
                                }}
                                onClick={AddTeam}
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box className=" my-autocomplete autocomplete-list-box">
                        <Stack spacing={2}>
                          <Box className="search-team">
                            <Autocomplete
                              className="card-inputt search-bar"
                              id="free-solo-demo"
                              name="searchTeam"
                              options={
                                searchValue.length > 2 ? getclientOptions : []
                              }
                              noOptionsText={
                                searchValue.length < 3
                                  ? "Search for Members"
                                  : "No options"
                              }
                              getOptionLabel={(option) =>
                                `${option.firstName} ${option.lastName}`
                              }
                              filterOptions={(options, { inputValue }) =>
                                options.filter((option) =>
                                  `${option?.lastName} ${option?.petName}`
                                    ?.toLowerCase()
                                    ?.includes(inputValue?.toLowerCase())
                                )
                              }
                              onInputChange={(event, newInputValue) => {
                                setSearchValue(newInputValue);
                              }}
                              onChange={handleCategoryChange}
                              renderOption={(props, option) => (
                                <li {...props} key={option.sortKey}>
                                  {`${option.firstName} ${option.lastName}`}
                                </li>
                              )}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <span
                                    {...getTagProps({ index })}
                                    key={option.sortKey}
                                  >
                                    {option.lastName.substring(
                                      0,
                                      Math.floor(option.lastName.length / 2)
                                    )}
                                  </span>
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  style={{ width: "100%" }}
                                  className="myCustomAutocomplete"
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <TeamSearchIcon
                                        onClick={() => {
                                          const inputValue =
                                            params.inputProps.value;
                                          setSearchValue(inputValue);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          position: "absolute",
                                          right: "25px",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          margin: 0,
                                        }}
                                      />
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Stack>

                        <Box className="save-car" sx={{ mt: 1 }}>
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              style={{
                                marginBottom: "3.2em",
                                color: "#003087",
                              }}
                              name="agreeBox"
                              className="agree-select"
                              onChange={handleCheckBoxChange}
                            />
                            <p
                              style={{ marginLeft: "0px" }}
                              className=" modal-description-team"
                            >
                              By proceeding with adding a person into your
                              family, you acknowledge that you are granting them
                              control over your dog's sessions, which may also
                              be utilized for their dog's needs.
                            </p>
                          </label>
                        </Box>
                        {isError && (
                          <Typography
                            style={{
                              color: "red",
                              marginVertical: 5,
                              paddingLeft: 12,
                            }}
                          >
                            {isError}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box>
              {type === "invite" ? (
                <CustomButton
                  className="book-btn"
                  title={"Invite Now"}
                  color="#fff"
                  backgroundColor="#32B2AC"
                  onClick={handleNext}
                />
              ) : openConfirmation ? (
                ""
              ) : (
                <CustomButton
                  className="book-btn"
                  title={"Add To Your Family"}
                  color="#fff"
                  backgroundColor="#003087"
                  fullWidth
                  onClick={openConfirmationModal}
                />
              )}
            </Box>
          </>
        ) : type === "availableCredits" ? (
          <>
            <Box className="modal-header">
              <Button
                onClick={() => {
                  handleClose();
                }}
                className="close-button"
              >
                <CloseIcon />
              </Button>
            </Box>
            <Box className={`team-main availableCredits`}>
              <Typography
                className="team-main-heading"
                color={"#003087 !important"}
              >
                Available Credits
              </Typography>
              <Box className="teams-content availableCredits-popup-content">
                <ul className="availableCredits-popup-content-inner">
                  {availableCredits &&
                  availableCredits.length === 0 
                   ? 
                    <Typography>No Credits Found</Typography>
                   : availableCredits && availableCredits.length ? (
                    availableCredits
                      .map((item, index) => (
                        <ListItem
                          sx={
                            index < 0
                              ? {}
                              : {
                                  borderBottom:
                                    "1px solid rgba(217, 217, 217, 1)",
                                }
                          }
                          key={index}
                        >
                          <span>{item?.itemName}</span>
                          <span>{item?.remainingSession}</span>
                        </ListItem>
                      ))
                  ) : (
                    <Typography>No Credits Found</Typography>
                  )}
                </ul>
              </Box>
            </Box>
          </>
        ) : (
          <DialogContent>
            <Box className="modal-content-wrap">
              <Box className="modal-header">
                <Typography className="heading">Liability Wavier</Typography>
                <Button
                  onClick={async () => {
                    await clearSignature();
                    setFormData({
                      ...formData,
                      isLiabilityWaiverSigned: false,
                    });
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </Button>
              </Box>
              <Box className="terms-box">
                {liabilityWaiverContent ? (
                  <Typography
                    dangerouslySetInnerHTML={{ __html: liabilityWaiverContent }}
                  />
                ) : (
                  <Typography>
                    {" "}
                    I have voluntarily applied to participate in programs and
                    activities at the Zoom Room. I am aware that there are
                    inherent risks and hazards involved in activities and around
                    dogs, and I am voluntarily participating in these activities
                    with knowledge of potential dangersI have voluntarily
                    applied to participate in programs and activities at the
                    Zoom Room. I am aware that there are inherent risks and
                    hazards involved in activities and around dogs, and I am
                    voluntarily participating in these activities with knowledge
                    of potential dangersI have voluntarily applied to
                    participate in programs and activities at the Zoom Room. I
                    am aware that there are inherent risks and hazards involved
                    in activities and around dogs, and I am voluntarily
                    participating in these activities with knowledge of
                    potential dangers{" "}
                  </Typography>
                )}
              </Box>
              <Box className="save-card" sx={{ gap: 0 }}>
                <Checkbox
                  style={{ color: "#003087" }}
                  checked={formData?.policyCheck}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      policyCheck: e.target.checked,
                    })
                  }
                />
                <Typography>
                  I agree to Zoom Room’s liability waiver and terms and
                  conditions.
                </Typography>
              </Box>
              <Box
                className="booking-notes signature-box"
                onClick={() => setIsSign(true)}
              >
                {formData?.signatureImage ? (
                  <img
                    src={formData?.signatureImage}
                    alt="signature"
                    height={"104px"}
                  />
                ) : isSign ? (
                  <SignaturePad
                    penColor="black"
                    ref={(ref) => {
                      sigCanvas = ref;
                    }}
                    onChange={handleSign}
                    canvasProps={{ className: "signature-canvas" }}
                  />
                ) : (
                  <Link className="see-more">Sign Here</Link>
                )}
              </Box>
              {formData?.signatureImage &&
                formData?.signImg &&
                formData?.signature && (
                  <Box className="clear-link">
                    <Button onClick={clearSignature}>Clear</Button>
                  </Box>
                )}
              {signError ? (
                <Typography color="error" variant="body2">
                  Please accept Terms and Conditions and add your signature.
                </Typography>
              ) : (
                ""
              )}
              <CustomButton
                className="book-btn"
                fontSize={16}
                title={"Submit"}
                color="#fff"
                backgroundColor="#32B2AC"
                iconJsx={<ChevronRightIcon />}
                fullWidth
                onClick={handleCloseModal}
              />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
